import _ from "lodash"
import algoliasearch from "algoliasearch"
import getConfig from "next/config"

import removeDiacritics from "../helpers/Diacritics"
import zerofill from "../helpers/zerofill"
import codeRegions from "../datas/codeRegions"
import reverseCodeRegions from "../datas/reverseCodeRegions"
import regionsURL from "../datas/regionsURL"
import departments from "../datas/departments"
import specialCaseCpGPS from "../datas/specialCaseCpGPS"
import reverseSpecialCase from "../datas/reverseSpecialCase"
import typesLabels from "../datas/ProspectionTypes"

if (!String.prototype.startsWith) {
    String.prototype.startsWith = function(search, pos) {
        return this.substr(!pos || pos < 0 ? 0 : +pos, search.length) === search
    }
}

let client = null

const getEpciLocationsList = siren => {
    const { publicRuntimeConfig } = getConfig()

    if (client === null) {
        client = algoliasearch(
            publicRuntimeConfig.algoliaApplicationID,
            publicRuntimeConfig.algoliaSearchOnlyAPIKeyLocations
        )
    }

    const index = client.initIndex(publicRuntimeConfig.algoliaEpciIndexName)
    const index2 = client.initIndex(publicRuntimeConfig.algoliaCommunesIndexName)

    var toSplit = ["Paris", "Marseille", "Lyon"]

    var stateNames = _.invert(codeRegions)

    return new Promise((resolve, reject) => {
        index
            .search({
                facetFilters: ["objectID:" + siren]
            })
            .then(res => {
                var insee = []

                if (res.nbHits === 1) {
                    const list = res.hits[0].list

                    for (var j = 0; j < list.length; j++) {
                        insee.push("objectID:" + res.hits[0].list[j].insee)
                    }
                } else {
                    reject("step 1")
                }

                return insee
            })
            .then(insee => {
                index2
                    .search({
                        facetFilters: [insee],
                        hitsPerPage: 1000
                    })
                    .then(res => {
                        var locations = translateAlgoliaHitsToLocations(
                            res.hits,
                            toSplit,
                            false,
                            stateNames,
                            0,
                            "",
                            false
                        )

                        resolve(locations)
                    })
            })
    })
}

// Check if location A intersects with location B
const locationIntersects = async (locationA, locationB) => {
    return (await locationContains(locationA, locationB, true)) || (await locationContains(locationB, locationA, true))
}

// Check if location A contains location B
const locationContains = async (locationA, locationB, intersection = false) => {
    if (locationA.id === locationB.id) {
        return true
    }

    if (
        locationA.type === 'country' &&
        locationA.id === 'country_fr'
    ) {
        if (locationB.type !== 'country') {
            return true
        }
    }

    if (
        locationA.type === 'region' &&
        locationB.type === 'departement'
    ) {
        const codesDepartement = departments
            .filter(d => d.codeRegion === locationA.codeRegion)
            .map(d => d.code)
        if (codesDepartement.indexOf(locationB.codeDepartement) !== -1) {
            return true
        }
    }
    if (
        locationA.type === 'region' &&
        locationB.type === 'epci'
    ) {
        if (locationA.codeRegion === locationB.codeRegion) {
            return true
        }
    }
    if (
        locationA.type === 'region' &&
        ['commune', 'commune_all'].indexOf(locationB.type) !== -1
    ) {
        if (locationA.codeRegion === locationB.codeRegion) {
            return true
        }
    }
    if (
        locationA.type === 'departement' &&
        locationB.type === 'epci'
    ) {
        if (
            locationA.codeRegion === locationB.codeRegion &&
            locationA.codeDepartement === locationB.codeDepartement
        ) {
            return true
        }
    }
    if (
        locationA.type === 'departement' &&
        ['commune', 'commune_all'].indexOf(locationB.type) !== -1
    ) {
        if (locationA.codeDepartement === locationB.codeDepartement) {
            return true
        }
    }
    if (
        locationA.type === 'epci' &&
        locationB.type === 'commune_all'
    ) {
        if (
            locationA.codeRegion === locationB.codeRegion &&
            locationA.codeDepartement === locationB.codeDepartement
        ) {
            const epciLocations = await getEpciLocationsList(locationA.siren)
            const epciLocationsIds = epciLocations.map(l => l.id)
            const communeAllLocationsIds = locationB
                .codePostaux
                .split('_')
                .map(o => `commune_${locationB.code}_${o}`)

            if (
                !intersection &&
                _.intersection(epciLocationsIds, communeAllLocationsIds).length === communeAllLocationsIds.length
            ) {
                return true
            }
            if (
                intersection &&
                _.intersection(epciLocationsIds, communeAllLocationsIds).length > 0
            ) {
                return true
            }
        }
    }
    if (
        locationA.type === 'epci' &&
        locationB.type === 'commune'
    ) {
        if (
            locationA.codeRegion === locationB.codeRegion &&
            locationA.codeDepartement === locationB.codeDepartement
        ) {
            const locations = await getEpciLocationsList(locationA.siren)

            for (let i = 0; i < locations.length; i++) {
                if (locations[i].id === locationB.id) {
                    return true
                }
            }
        }
    }
    if (
        locationA.type === 'commune_all' &&
        locationB.type === 'commune'
    ) {
        if (locationA.code === locationB.code) {
            return true
        }
    }

    return false
}

export const locationMatches = (locationA, locationB, lazy = false) => {
    return new Promise((resolve, reject) => {
        var ok = true

        if (
            (locationA.type === "country" && locationA.id === "country_fr") ||
            (locationB.type === "country" && locationB.id === "country_fr")
        ) {
            resolve(ok)
        }

        if (
            locationA.type === "commune" &&
            locationB.type === "commune_all" &&
            locationA.codeDepartement === locationB.codeDepartement &&
            locationA.codePostal &&
            locationB.codePostaux
        ) {
            const codePostaux = locationB.codePostaux.split('_')
            if (codePostaux.indexOf(locationA.codePostal) !== -1) {
                resolve(ok)
            }
        }

        if (locationA.type === "commune" && locationB.type === "epci") {
            if (
                locationA.codeRegion === locationB.codeRegion &&
                locationA.codeDepartement === locationB.codeDepartement
            ) {
                getEpciLocationsList(locationB.siren).then(res => {
                    ok = false

                    for (var i = 0; i < res.length; i++) {
                        if (res[i].id === locationA.id) {
                            ok = true
                            break
                        }
                    }

                    resolve(ok)
                })
            } else {
                resolve(false)
            }
        } else if (locationA.type === "epci" && locationB.type === "commune") {
            if (
                locationA.codeRegion === locationB.codeRegion &&
                locationA.codeDepartement === locationB.codeDepartement
            ) {
                getEpciLocationsList(locationA.siren).then(res => {
                    ok = false

                    for (var i = 0; i < res.length; i++) {
                        if (res[i].id === locationB.id) {
                            ok = true
                            break
                        }
                    }

                    resolve(ok)
                })
            } else {
                resolve(false)
            }
        } else if (
            (locationA.type === "commune" ||
                locationA.type === "commune_all") &&
            locationB.type === "departement" &&
            lazy === true
        ) {
            if (locationA.codeDepartement !== locationB.codeDepartement)
                ok = false
            resolve(ok)
        } else {
            if (locationA.type === "commune") {
                if (locationA.codeDepartement !== locationB.codeDepartement)
                    ok = false

                if (typeof locationA.codesPostaux !== "undefined") {
                    if (
                        typeof locationB.codesPostaux === "undefined" ||
                        !_.isEqual(
                            locationA.codesPostaux,
                            locationB.codesPostaux
                        ) ||
                        locationA.code !== locationB.code
                    ) {
                        ok = false
                    }
                }

                if (typeof locationA.codePostal !== "undefined") {
                    if (
                        typeof locationB.codePostal === "undefined" ||
                        locationA.codePostal !== locationB.codePostal ||
                        locationA.code !== locationB.code
                    ) {
                        ok = false
                    }
                }
            } else if (locationA.type === "commune_all") {
                if (
                    (locationB.type !== "commune_all" &&
                        typeof locationB.codePostal === "undefined") ||
                    (locationB.type !== "commune_all" &&
                        locationA.codePostaux.indexOf(locationB.codePostal) ===
                            -1) ||
                    (locationB.type === "commune_all" &&
                        !_.isEqual(
                            locationA.codePostaux,
                            locationB.codePostaux
                        ))
                ) {
                    ok = false
                }
            } else if (locationA.type === "departement") {
                if (locationA.codeDepartement !== locationB.codeDepartement)
                    ok = false
            }

            resolve(ok)
        }
    })
}

const allowedState = value => {
    if (
        value === "01" ||
        value === "02" ||
        value === "03" ||
        value === "04" ||
        value === "06"/* ||
        value === "94" */
    ) {
        return false
    }

    return true
}

const allowedDepartement = value => {
    if (
        value === "971" ||
        value === "972" ||
        value === "973" ||
        value === "974" ||
        value === "975" ||
        value === "976" /*||
        value === "2A" ||
        value === "2B"*/
    ) {
        return false
    }

    return true
}

const allowedCodePostal = value => {
    // 971 Guadeloupe 973 Guyane 975 St-Pierre-et-Miquelon
    // 972 Martinique 974 La Réunion 976 Mayotte

    if (
        value.startsWith("971") ||
        value.startsWith("972") ||
        value.startsWith("973") ||
        value.startsWith("974") ||
        value.startsWith("975") ||
        value.startsWith("976")/* ||
        value.startsWith("20")*/
    ) {
        return false
    }

    return true
}

const translateAlgoliaHitsToLocations = (
    citiesResponse,
    toSplit,
    groupEnabled,
    stateNames,
    minCount,
    value_save,
    number,
    disableSplitting = false,
    locations_restrict = [],
    disable_commune_all_inclusion = false
) => {
    var list = []
    _.forEach(citiesResponse, function(value, key) {
        if (value.type === 'region') {
            list.push({
                libelle: value.nom,
                nom: value.nom,
                codeRegion: value.codeRegion
            })
            return
        }
        if (_.indexOf(toSplit, value.nom) != -1) {
            var latitude = null
            var longitude = null

            if (value.centre && value.centre.type === "Point") {
                latitude = value.centre.coordinates[1]
                longitude = value.centre.coordinates[0]
            }

            if (groupEnabled && !number) {
                list.push({
                    state: stateNames[value.codeRegion],
                    libelle: value.nom + " (Tout arrondissement)",
                    nom: value.nom,
                    codeRegion: value.codeRegion,
                    codeDepartement: value.codeDepartement,
                    population: value.population,
                    codePostaux: value.codesPostaux.join("_"),
                    code: value.code,
                    type: "commune_all",
                    id: "commune_all_" + value.code,
                    lat: latitude,
                    long: longitude
                })
            }

            if (!disableSplitting) {
                var i = 1

                _.forEach(value.codesPostaux, function(value2, key2) {
                    if (
                        (/^\d+$/.test(value_save) && value_save == value2) ||
                        !/^\d+$/.test(value_save)
                    ) {
                        var ok = true

                        if (/^\d+$/.test(value_save)) {
                            i = parseInt(value_save.substring(3))
                        }

                        if (number !== false) {
                            var regex = new RegExp(
                                (number.toString().length == 1 ? "0" : "") +
                                    number +
                                    "$",
                                "gi"
                            )

                            if (!regex.test(value2)) ok = false
                        }

                        if (ok) {
                            if (value2 == "75016") {
                                if (number !== false) {
                                    if (number.toString().length <= 2) {
                                        i = number
                                    } else {
                                        i = parseInt(number.toString().substr(3, number.toString().length - 1))
                                    }
                                }
                                var locations_filtered = locations_restrict.filter(
                                    location =>
                                        (location.type === "region" &&
                                            location.codeRegion &&
                                            location.codeRegion ===
                                                value.codeRegion) ||
                                        (location.type === "departement" &&
                                            location.codeDepartement &&
                                            location.codeDepartement ===
                                                value.codeDepartement) ||
                                        (location.type === "commune" &&
                                            location.codePostal &&
                                            location.codePostal === value2 &&
                                            location.code &&
                                            location.code === value.code) ||
                                        (location.type === "commune_all" &&
                                            location.codeDepartement ===
                                                value.codeDepartement)
                                )

                                if (
                                    (disable_commune_all_inclusion &&
                                        locations_filtered.length > 0) ||
                                    !disable_commune_all_inclusion
                                ) {
                                    list.push({
                                        state: stateNames[value.codeRegion],
                                        libelle:
                                            value.nom +
                                            " " +
                                            i +
                                            (i > 1 ? "ème" : "er") +
                                            " (" +
                                            value2 +
                                            ", 75116)",
                                        nom: value.nom,
                                        codeRegion: value.codeRegion,
                                        codeDepartement: value.codeDepartement,
                                        population: value.population,
                                        codePostal: value2,
                                        type: "commune",
                                        id: "commune_" + value.code + "_" + value2,
                                        lat: specialCaseCpGPS[value2] ? specialCaseCpGPS[value2].lat : latitude,
                                        long: specialCaseCpGPS[value2] ? specialCaseCpGPS[value2].lon : longitude,
                                        code: value.code,
                                        codePostaux: value.codesPostaux.join("_")
                                    })
                                }

                                i++
                            } else {
                                if (value2 != "75116") {
                                    if (number !== false) {
                                        if (number.toString().length <= 2) {
                                            i = number
                                        } else {
                                            i = parseInt(number.toString().substr(3, number.toString().length - 1))
                                        }
                                    }
                                    var locations_filtered = locations_restrict.filter(
                                        location =>
                                            (location.type === "region" &&
                                                location.codeRegion &&
                                                location.codeRegion ===
                                                    value.codeRegion) ||
                                            (location.type === "departement" &&
                                                location.codeDepartement &&
                                                location.codeDepartement ===
                                                    value.codeDepartement) ||
                                            (location.type === "commune" &&
                                                location.codePostal &&
                                                location.codePostal === value2 &&
                                                location.code &&
                                                location.code === value.code) ||
                                            (location.type === "commune_all" &&
                                                location.codeDepartement ===
                                                    value.codeDepartement)
                                    )

                                    if (
                                        (disable_commune_all_inclusion &&
                                            locations_filtered.length > 0) ||
                                        !disable_commune_all_inclusion
                                    ) {
                                        list.push({
                                            state: stateNames[value.codeRegion],
                                            libelle:
                                                value.nom +
                                                " " +
                                                i +
                                                (i > 1 ? "ème" : "er") +
                                                " (" +
                                                value2 +
                                                ")",
                                            nom: value.nom,
                                            codeRegion: value.codeRegion,
                                            codeDepartement: value.codeDepartement,
                                            population: value.population,
                                            codePostal: value2,
                                            type: "commune",
                                            id:
                                                "commune_" +
                                                value.code +
                                                "_" +
                                                value2,
                                            lat: specialCaseCpGPS[value2] ? specialCaseCpGPS[value2].lat : latitude,
                                            long: specialCaseCpGPS[value2] ? specialCaseCpGPS[value2].lon : longitude,
                                            code: value.code,
                                            codePostaux: value.codesPostaux.join(
                                                "_"
                                            )
                                        })
                                    }

                                    i++
                                }
                            }
                        }
                    }
                })

                if (value.codesPostaux.length + 1 > minCount)
                    minCount = value.codesPostaux.length + 1
            }
        } else {
            var latitude = null
            var longitude = null

            if (value.centre && value.centre.type === "Point") {
                latitude = value.centre.coordinates[1]
                longitude = value.centre.coordinates[0]
            }

            if (value.codesPostaux.length > 1) {
                var sValue = value.codesPostaux.join(", ")
                sValue =
                    sValue.length > 17
                        ? sValue.substring(0, 17) + "..."
                        : sValue

                if (allowedDepartement(value.codeDepartement)) {
                    list.push({
                        state: stateNames[value.codeRegion],
                        libelle:
                            value.nom +
                            " (" +
                            sValue +
                            ")",
                        nom: value.nom,
                        codeRegion: value.codeRegion,
                        codeDepartement: value.codeDepartement,
                        population: value.population,
                        codesPostaux: value.codesPostaux.join("_"),
                        type: "commune",
                        id:
                            "commune_" +
                            value.code +
                            "_" +
                            value.codesPostaux.join("_"),
                        lat: latitude,
                        long: longitude,
                        code: value.code
                    })
                }
            } else {
                if (allowedCodePostal(value.codesPostaux[0])) {
                    list.push({
                        state: stateNames[value.codeRegion],
                        libelle:
                            value.nom +
                            " (" +
                            value.codesPostaux[0] +
                            ")",
                        nom: value.nom,
                        codeRegion: value.codeRegion,
                        codeDepartement: value.codeDepartement,
                        population: value.population,
                        codePostal: value.codesPostaux[0],
                        type: "commune",
                        id:
                            "commune_" +
                            value.code +
                            "_" +
                            value.codesPostaux[0],
                        lat: specialCaseCpGPS[value.codesPostaux[0]] ? specialCaseCpGPS[value.codesPostaux[0]].lat : latitude,
                        long: specialCaseCpGPS[value.codesPostaux[0]] ? specialCaseCpGPS[value.codesPostaux[0]].lon : longitude,
                        code: value.code
                    })
                }
            }
        }
    })

    return list
}

export const buildLocationsTags = async (locations, prefix = '') => {
    const clone_locations = [...locations]
    for (let i = 0; i < clone_locations.length; i++) {
        if (clone_locations[i].type === 'epci') {
            if (typeof clone_locations[i].siren !== 'undefined') {
                locations = _.concat(
                    locations,
                    await getEpciLocationsList(clone_locations[i].siren)
                )
            }
        }
    }

    let tags = []
    let toAdd

    const props_to_tags = [
        'codeDepartement',
        'codePostal',
        'codePostaux',
        'codeRegion',
        'id',
        'nom',
        'type'
    ]

    for (let i = 0; i < locations.length; i++) {
        let properties = _.keys(locations[i])

        for (let j = 0; j < properties.length; j++) {
            if (props_to_tags.indexOf(properties[j]) !== -1) {
                if (properties[j] === 'codePostaux') {
                    let codePostauxSplit = locations[i].codePostaux.split('_')
                    for (let k = 0; k < codePostauxSplit.length; k++) {
                        if (codePostauxSplit[k].trim() !== '') {
                            toAdd = 'locations_codePostaux_' + codePostauxSplit[k]
                            if (tags.indexOf(toAdd) === -1)
                                tags.push(prefix + (prefix !== '' ? '_' : '') + toAdd)
                        }
                    }
                } else {
                    toAdd =
                        'locations_' + properties[j] + '_' + locations[i][properties[j]]
                    if (tags.indexOf(toAdd) === -1)
                        tags.push(prefix + (prefix !== '' ? '_' : '') + toAdd)
                }
            }
        }
    }

    return _.uniq(tags)
}

export const buildLocations = (
    value,
    groupEnabled = true,
    citiesEnabled = true,
    epciEnabled = true,
    filters = {},
    departmentEnabled = true,
    stateEnabled = false
) => {
    var toSplit = ["Paris", "Marseille", "Lyon"]

    var stateNames = _.invert(codeRegions)

    // var regex = new RegExp('^le |la |les ', 'gi')

    // value = value.replace(regex, '')

    var number = false

    for (var i = 0; i < toSplit.length; i++) {
        var match
        var regex = new RegExp(toSplit[i] + " ([0-9]+)", "gi")

        if ((match = regex.exec(value)) != null) {
            number = parseInt(match[1])

            value = toSplit[i]
        }
    }

    var algoliaQuery = new Promise((resolve, reject) => {
        new Promise((resolve2, reject2) => {
            if (filters.locations && filters.locations.length > 0) {
                translateLocationsToAlgoliaFilters(filters.locations).then(
                    res => {
                        resolve2(res)
                    }
                )
            } else {
                resolve2([])
            }
        }).then(locationsFilter => {
            let queries = []
            const { publicRuntimeConfig } = getConfig()

            if (citiesEnabled) {
                queries.push({
                    indexName: publicRuntimeConfig.algoliaCommunesIndexName,
                    query: value.trim(),
                    params: {
                        hitsPerPage: filters.hits_per_page || 10,
                        filters:
                            `${locationsFilter.length > 0
                                ? `(${locationsFilter.join(" OR ")})`
                                : ""}${filters.excludeLigne5 ? ` ${locationsFilter.length > 0 ? 'AND' : ''} NOT _tags:locations_subtype_ligne_5` : ''}`,
                        facetFilters:
                            typeof filters.codeRegion != "undefined"
                                ? ["codeRegion:" + filters.codeRegion]
                                : []
                    }
                })
            }

            if (groupEnabled && departmentEnabled) {
                queries.push({
                    indexName: publicRuntimeConfig.algoliaDepartementsIndexName,
                    query: value.trim(),
                    params: {
                        hitsPerPage: filters.hits_per_page || 10,
                        filters: !filters.allowAll ? "NOT objectID:france" : "",
                        facetFilters:
                            typeof filters.codeRegion != "undefined"
                                ? ["codeRegion:" + filters.codeRegion]
                                : []
                    }
                })
            }

            if (groupEnabled && epciEnabled) {
                queries.push({
                    indexName: publicRuntimeConfig.algoliaEpciIndexName,
                    query: value.trim(),
                    params: {
                        hitsPerPage: filters.hits_per_page || 10,
                        facetFilters:
                            typeof filters.codeRegion != "undefined"
                                ? ["region:" + filters.codeRegion]
                                : []
                    }
                })
            }

            if (groupEnabled && stateEnabled) {
                queries.push({
                    indexName: publicRuntimeConfig.algoliaStateIndexName,
                    query: value.trim(),
                    params: {
                        hitsPerPage: filters.hits_per_page || 10,
                        facetFilters:
                            typeof filters.codeRegion != "undefined"
                                ? ["objectID:" + filters.codeRegion]
                                : []
                    }
                })
            }

            if (client === null) {
                client = algoliasearch(
                    publicRuntimeConfig.algoliaApplicationID,
                    publicRuntimeConfig.algoliaSearchOnlyAPIKeyLocations
                )
            }

            client.search(queries, (err, content) => {
                if (err) {
                    reject(err)
                    return
                }

                resolve(content)
            })
        })
    })

    var value_save = value

    return new Promise((resolve, reject) => {
        algoliaQuery
            .then(res => {
                var list = []

                var i = 0

                if (citiesEnabled) {
                    var citiesResponse = res.results[i++].hits
                }

                if (groupEnabled && departmentEnabled) {
                    var departmentResponse = res.results[i++].hits
                }

                if (groupEnabled && epciEnabled) {
                    var epciResponse = res.results[i++].hits
                }

                if (groupEnabled && stateEnabled) {
                    var stateResponse = res.results[i++].hits
                }

                var minDept = 0

                if (typeof epciResponse !== "undefined") {
                    _.forEach(epciResponse, function(value, key) {
                        if (allowedDepartement(value.dep)) {
                            list.push({
                                state: stateNames[value.region],
                                libelle: value.name,
                                nom: value.name,
                                codeRegion: value.region,
                                codeDepartement: value.dep,
                                type: "epci",
                                subType: value.type,
                                siren: value.siren,
                                ptot: value.ptot,
                                pmun: value.pmun,
                                id: "epci_" + value.siren
                            })

                            minDept++
                        }
                    })
                }

                if (departmentResponse) {
                    _.forEach(departmentResponse, function(value, key) {
                        if (
                            value.code != 75 &&
                            allowedDepartement(value.code)
                        ) {
                            list.push({
                                state: !isNaN(value.code) || ['2A', '2B'].indexOf(value.code) !== -1
                                    ? stateNames[value.codeRegion]
                                    : "all",
                                libelle:
                                    value.nom +
                                    (!isNaN(value.code) || ['2A', '2B'].indexOf(value.code) !== -1
                                        ? " - " + value.code
                                        : ""),
                                nom: value.nom,
                                codeRegion: value.codeRegion,
                                codeDepartement: value.code,
                                type: !isNaN(value.code) || ['2A', '2B'].indexOf(value.code) !== -1
                                    ? "departement"
                                    : "country",
                                id:
                                    (!isNaN(value.code) || ['2A', '2B'].indexOf(value.code) !== -1
                                        ? "departement"
                                        : "country") +
                                    "_" +
                                    value.code
                            })

                            minDept++
                        }
                    })
                }

                var minState = 0
                if (stateResponse) {
                    _.forEach(stateResponse, function(value, key) {
                        if (
                            allowedState(value.objectID)
                        ) {
                            const stateNames = _.invert(codeRegions)
                            list.push({
                                state: stateNames[value.objectID],
                                libelle: value.nom,
                                nom: value.nom,
                                type: "region",
                                codeRegion: value.objectID,
                                id: `region_${value.objectID}`
                            })

                            minState++
                        }
                    })
                }

                var minCount = 10

                list = _.concat(
                    list,
                    translateAlgoliaHitsToLocations(
                        citiesResponse,
                        toSplit,
                        groupEnabled,
                        stateNames,
                        minCount,
                        value_save,
                        number,
                        false,
                        filters.locations,
                        filters.disable_commune_all_inclusion &&
                        filters
                            .locations
                            .filter(l => l.id === 'country_fr')
                            .length === 0 ?
                                true : false
                    )
                )

                resolve(_.take(list, minCount + minDept + minState))
            })
            .catch(err => {
                reject(err)
            })
    })
}

const pushUnique = (a, o) => {
    if (a.indexOf(o) === -1) a.push(o)

    return a
}

export const translateLocationsToAlgoliaFilters = (locations, prefix = "") => {
    var criterias = []
    var p = []

    const clone_locations = [...locations]
    for (var i = 0; i < clone_locations.length; i++) {
        if (clone_locations[i].type === "epci") {
            if (typeof clone_locations[i].siren !== "undefined") {
                criterias.push(
                    "_tags:" +
                        (prefix + (prefix !== "" ? "_" : "")) +
                        "epci_" +
                        clone_locations[i].siren
                )

                p.push(getEpciLocationsList(clone_locations[i].siren))
            }
        }
    }

    return new Promise((resolve, reject) => {
        Promise.all(p)
            .then(results => {
                for (let i = 0; i < results.length; i++) {
                    locations = _.concat(locations, results[i])
                }

                for (let i = 0; i < locations.length; i++) {
                    if (locations[i].type === 'region') {
                        pushUnique(
                            criterias,
                            '_tags:locations_codeRegion_' + locations[i].codeRegion
                        )
                    } else if (locations[i].type === "departement") {
                        pushUnique(
                            criterias,
                            "_tags:" +
                                (prefix + (prefix !== "" ? "_" : "")) +
                                "locations_codeDepartement_" +
                                locations[i].codeDepartement
                        )

                        if (
                            typeof locations[i].codeRegion !== "undefined"
                        ) {
                            pushUnique(
                                criterias,
                                "_tags:" +
                                    (prefix + (prefix !== "" ? "_" : "")) +
                                    "locations_id_region_" +
                                    locations[i].codeRegion
                            )
                        }
                    } else if (locations[i].type === "commune_all") {
                        pushUnique(
                            criterias,
                            "_tags:" +
                                (prefix + (prefix !== "" ? "_" : "")) +
                                "locations_id_commune_all_" +
                                locations[i].code
                        )

                        var codePostauxSplit = locations[i].codePostaux.split(
                            "_"
                        )
                        for (var k = 0; k < codePostauxSplit.length; k++) {
                            pushUnique(
                                criterias,
                                "_tags:" +
                                    (prefix + (prefix !== "" ? "_" : "")) +
                                    "locations_id_commune_" +
                                    locations[i].code +
                                    "_" +
                                    codePostauxSplit[k]
                            )
                        }

                        if (
                            typeof locations[i].codeDepartement !== "undefined"
                        ) {
                            pushUnique(
                                criterias,
                                "_tags:" +
                                    (prefix + (prefix !== "" ? "_" : "")) +
                                    "locations_id_departement_" +
                                    locations[i].codeDepartement
                            )
                        }

                        if (
                            typeof locations[i].codeRegion !== "undefined"
                        ) {
                            pushUnique(
                                criterias,
                                "_tags:" +
                                    (prefix + (prefix !== "" ? "_" : "")) +
                                    "locations_id_region_" +
                                    locations[i].codeRegion
                            )
                        }

                        pushUnique(
                            criterias,
                            "_tags:" +
                                (prefix + (prefix !== "" ? "_" : "")) +
                                "locations_id_commune_all_" +
                                locations[i].code
                        )

                        if (typeof locations[i].nom !== 'undefined') {
                            pushUnique(
                              criterias,
                              '_tags:' +
                                (prefix + (prefix !== '' ? '_' : '')) +
                                'locations_nom_' +
                                locations[i].nom
                            )
                        }
                    } else if (locations[i].type === "commune") {
                        if (typeof locations[i].codesPostaux !== "undefined") {
                            pushUnique(
                                criterias,
                                "_tags:" +
                                    (prefix + (prefix !== "" ? "_" : "")) +
                                    "locations_id_commune_" +
                                    locations[i].code +
                                    "_" +
                                    locations[i].codesPostaux
                            )
                        } else if (
                            typeof locations[i].codePostal !== "undefined"
                        ) {
                            pushUnique(
                                criterias,
                                "_tags:" +
                                    (prefix + (prefix !== "" ? "_" : "")) +
                                    "locations_id_commune_" +
                                    locations[i].code +
                                    "_" +
                                    locations[i].codePostal
                            )
                        }

                        if (typeof locations[i].code !== "undefined") {
                            pushUnique(
                                criterias,
                                "_tags:" +
                                    (prefix + (prefix !== "" ? "_" : "")) +
                                    "locations_id_commune_all_" +
                                    locations[i].code
                            )
                        }

                        if (
                            typeof locations[i].codeDepartement !== "undefined"
                        ) {
                            pushUnique(
                                criterias,
                                "_tags:" +
                                    (prefix + (prefix !== "" ? "_" : "")) +
                                    "locations_id_departement_" +
                                    locations[i].codeDepartement
                            )
                        }

                        if (
                            typeof locations[i].codeRegion !== "undefined"
                        ) {
                            pushUnique(
                                criterias,
                                "_tags:" +
                                    (prefix + (prefix !== "" ? "_" : "")) +
                                    "locations_id_region_" +
                                    locations[i].codeRegion
                            )
                        }
                    }
                }

                resolve(criterias)
            })
            .catch(error => {
                console.error("ERROR: ", error)
            })
    })
}

export const constructBreadcrumbsFromLocation = (
    state,
    location,
    originParameters,
    urlPrefix = "",
    transaction = null,
    postalCodeInseeMatching = null
) => {
    let navigation = []

    /*
    { state: 'Grand Est',
     libelle: 'Metz (57000, 57050, 570...)',
     nom: 'Metz',
     codeRegion: '44',
     codeDepartement: '57',
     population: 118634,
     codesPostaux: '57000_57050_57070',
     type: 'commune',
     id: 'commune_57463_57000_57050_57070',
     lat: 6.1940299268418,
     long: 49.110238705278,
     code: '57463' },
    */

    if (state && !location) {
        let reverseRegionsURL = _.invert(regionsURL)

        navigation.push({
            name: reverseRegionsURL[state],
            url: `${urlPrefix}/${state}${transaction ? "/" + transaction : ""}`
        })
    }

    if (
        location &&
        (location.type === "commune" || location.type === "commune_all")
    ) {
        let stateLibelle = reverseCodeRegions[location.codeRegion]
        let stateSEO = regionsURL[stateLibelle]

        let department = departments.filter(
            d => d.code === location.codeDepartement
        )
        if (department.length === 0) {
            return false
        }

        let departmentLibelle = department[0].name + " - " + department[0].code
        let departmentSEO = department[0].seo

        let locationLibelle = location.libelle
        if (
            postalCodeInseeMatching &&
            typeof postalCodeInseeMatching[zerofill(location.code, 5)] !== 'undefined'
        ) {
            const searchSpecialCase = reverseSpecialCase.filter(o => location.codePostal && o.insee === location.code && o.cp === location.codePostal)
            let insee = location.code
            if (searchSpecialCase.length > 0) {
                insee = searchSpecialCase[0].real_insee
            }
            locationLibelle = locationLibelle
                .replace(/\(.+\)/i, `(${postalCodeInseeMatching[zerofill(insee, 5)]})`)
        }
        let locationSEO = originParameters.city

        navigation.push({
            name: stateLibelle,
            url: `${urlPrefix}/${stateSEO}${
                transaction ? "/" + transaction : ""
            }`
        })

        if (
            ["paris-75"].indexOf(departmentSEO) !== -1 &&
            location.type === "commune"
        ) {
            navigation.push({
                name: departmentLibelle,
                url: `${urlPrefix}/${stateSEO}/paris-75000${
                    transaction ? "/" + transaction : ""
                }`
            })
        }
        if (["paris-75"].indexOf(departmentSEO) === -1) {
            navigation.push({
                name: departmentLibelle,
                url: `${urlPrefix}/${stateSEO}/${departmentSEO}${
                    transaction ? "/" + transaction : ""
                }`
            })
        }

        navigation.push({
            name: locationLibelle,
            url: `${urlPrefix}${
                ["paris-75"].indexOf(departmentSEO) === -1
                    ? `/${departmentSEO}`
                    : `/${stateSEO}`
            }/${locationSEO}${transaction ? "/" + transaction : ""}`
        })
    }

    if (location && location.type === "departement") {
        let stateLibelle = reverseCodeRegions[location.codeRegion]
        let stateSEO = regionsURL[stateLibelle]

        let department = departments.filter(
            d => d.code === location.codeDepartement
        )
        if (department.length === 0) {
            return false
        }
        let departmentLibelle = `${department[0].name} - ${department[0].code}`
        let departmentSEO = department[0].seo

        navigation.push({
            name: stateLibelle,
            url: `${urlPrefix}/${stateSEO}${
                transaction ? "/" + transaction : ""
            }`
        })

        navigation.push({
            name: departmentLibelle,
            url: `${urlPrefix}/${stateSEO}/${departmentSEO}${
                transaction ? "/" + transaction : ""
            }`
        })
    }

    return navigation
}

export const constructBreadcrumbsFromLocationV2 = (
    type,
    state,
    location,
    originParameters,
    urlPrefix = "",
    transaction = null,
    postalCodeInseeMatching = null,
    citiesInsees = null,
    ligne5LocationsIds = null
) => {
    let navigation = []

    /*
    { state: 'Grand Est',
     libelle: 'Metz (57000, 57050, 570...)',
     nom: 'Metz',
     codeRegion: '44',
     codeDepartement: '57',
     population: 118634,
     codesPostaux: '57000_57050_57070',
     type: 'commune',
     id: 'commune_57463_57000_57050_57070',
     lat: 6.1940299268418,
     long: 49.110238705278,
     code: '57463' },
    */

    if (state && !location) {
        let reverseRegionsURL = _.invert(regionsURL)

        navigation.push({
            name: `${typesLabels[type].prefix} ${reverseRegionsURL[state]}`,
            url: `${urlPrefix}/${state}${transaction ? "/" + transaction : ""}`,
            type: 'state'
        })
    }

    if (
        location &&
        (location.type === "commune" || location.type === "commune_all")
    ) {
        let stateLibelle = reverseCodeRegions[location.codeRegion]
        let stateSEO = regionsURL[stateLibelle]

        let department = departments.filter(
            d => d.code === location.codeDepartement
        )
        if (department.length === 0) {
            return false
        }

        let departmentLibelle = department[0].name + " - " + department[0].code
        let departmentSEO = department[0].seo

        let locationLibelle = location.libelle
        if (
            postalCodeInseeMatching &&
            typeof postalCodeInseeMatching[zerofill(location.code, 5)] !== 'undefined'
        ) {
            const searchSpecialCase = reverseSpecialCase.filter(o => location.codePostal && o.insee === location.code && o.cp === location.codePostal)
            let insee = location.code
            if (searchSpecialCase.length > 0) {
                insee = searchSpecialCase[0].real_insee
            }
            locationLibelle = locationLibelle
                .replace(/\(.+\)/i, `(${postalCodeInseeMatching[zerofill(insee, 5)]})`)
        }
        let locationSEO = originParameters.city

        navigation.push({
            name: `${typesLabels[type].prefix} ${stateLibelle}`,
            url: `${urlPrefix}/${stateSEO}${
                transaction ? "/" + transaction : ""
            }`,
            type: 'state'
        })

        if (
            ["paris-75"].indexOf(departmentSEO) !== -1 &&
            location.type === "commune"
        ) {
            navigation.push({
                name: `${typesLabels[type].prefix} ${departmentLibelle}`,
                url: `${urlPrefix}/${stateSEO}/paris-75000${
                    transaction ? "/" + transaction : ""
                }`,
                type: 'department'
            })
        }
        if (["paris-75"].indexOf(departmentSEO) === -1) {
            navigation.push({
                name: `${typesLabels[type].prefix} ${departmentLibelle}`,
                url: `${urlPrefix}/${stateSEO}/${departmentSEO}${
                    transaction ? "/" + transaction : ""
                }`,
                type: 'department'
            })
        }

        navigation.push({
            name: `${typesLabels[type].prefix} ${locationLibelle}`,
            url: (!ligne5LocationsIds || (ligne5LocationsIds && ligne5LocationsIds.indexOf(`${location.nom}_${location.id}`) === -1)) && (!citiesInsees || (citiesInsees && citiesInsees.indexOf(location.code) !== -1)) ? (`${urlPrefix}${
                ["paris-75"].indexOf(departmentSEO) === -1
                    ? `/${departmentSEO}`
                    : `/${stateSEO}`
            }/${locationSEO}${transaction ? "/" + transaction : ""}`) : null,
            type: 'city'
        })
    }

    if (location && location.type === "departement") {
        let stateLibelle = reverseCodeRegions[location.codeRegion]
        let stateSEO = regionsURL[stateLibelle]

        let department = departments.filter(
            d => d.code === location.codeDepartement
        )
        if (department.length === 0) {
            return false
        }
        let departmentLibelle = `${department[0].name} - ${department[0].code}`
        let departmentSEO = department[0].seo

        navigation.push({
            name: `${typesLabels[type].prefix} ${stateLibelle}`,
            url: `${urlPrefix}/${stateSEO}${
                transaction ? "/" + transaction : ""
            }`,
            type: 'state'
        })

        navigation.push({
            name: `${typesLabels[type].prefix} ${departmentLibelle}`,
            url: `${urlPrefix}/${stateSEO}/${departmentSEO}${
                transaction ? "/" + transaction : ""
            }`,
            type: 'department'
        })
    }

    return navigation
}

const getSEOId = (id, location = null) => {
    let newId = removeDiacritics(_.toLower(id))
        .replace(" - ", "-")
        .replace(/'/g, "-")
        .replace(/\(/g, "")
        .replace(/\)/g, "")
        .replace(", ", "-")
        .replace(/[^a-zA-Z0-9\- ]/g, "")
        .replace(/ /g, "-")
    if (location) {
        newId = newId.replace(
            "tout-arrondissement",
            location.codeDepartement + "000"
        )
    }
    return newId.replace(/\-([0-9]+(er|eme))\-/g, "-$1-arrondissement-")
}

const getSEOIdFromLocation = location => {
    let libelle = location.libelle
    if (location.codePostal) {
        libelle = libelle.replace(/\(.+\)/g, "(" + location.codePostal + ")")
    } else if (location.codesPostaux) {
        libelle = libelle.replace(
            /\(.+\)/g,
            "(" + location.codesPostaux.split("_")[0] + ")"
        )
    }
    return getSEOId(libelle, location)
}

const getSEOIdFromAlgoliaLocation = location => {
    let newId = location.nom.trim()
    newId = newId.replace(/\(.*\)/i, "").trim()
    newId = removeDiacritics(_.toLower(newId))
        .replace(" - ", "-")
        .replace(/'/g, "-")
        .replace(/\(/g, "")
        .replace(/\)/g, "")
        .replace(", ", "-")
        .replace(/[^a-zA-Z0-9\- ]/g, "")
        .replace(/ /g, "-") +
    "-" +
    location.codesPostaux[0]

    return newId
}

export { getSEOId, getSEOIdFromLocation, getSEOIdFromAlgoliaLocation }

export const convertDepartmentSeoToLocationEntity = seo => {
    let currentDepartment = departments.filter(o => o.seo === seo)

    if (currentDepartment.length !== 0) {
        return {
            codeDepartement: currentDepartment[0].code,
            codeRegion: currentDepartment[0].codeRegion,
            id: "departement_" + currentDepartment[0].code,
            libelle:
                currentDepartment[0].name + " - " + currentDepartment[0].code,
            nom: currentDepartment[0].name,
            state: reverseCodeRegions[currentDepartment[0].codeRegion],
            type: "departement"
        }
    }

    return null
}

export const extractDepartmentLocationFromLocation = location => {
    let currentDepartment = departments.filter(
        o => o.code === location.codeDepartement
    )

    if (currentDepartment.length !== 0) {
        return {
            codeDepartement: currentDepartment[0].code,
            codeRegion: currentDepartment[0].codeRegion,
            id: "departement_" + currentDepartment[0].code,
            libelle:
                currentDepartment[0].name + " - " + currentDepartment[0].code,
            nom: currentDepartment[0].name,
            state: reverseCodeRegions[currentDepartment[0].codeRegion],
            type: "departement"
        }
    }

    return null
}

export const getPageURLFromLocation = location => {
    if (location.type === "commune" || location.type === "commune_all") {
        let stateLibelle = reverseCodeRegions[location.codeRegion]
        let stateSEO = regionsURL[stateLibelle]

        let department = departments.filter(
            d => d.code === location.codeDepartement
        )
        if (department.length === 0) {
            return false
        }

        let departmentSEO = department[0].seo
        let locationSEO = getSEOIdFromLocation(location)

        return `${
            ["paris-75"].indexOf(departmentSEO) === -1
                ? `/${departmentSEO}`
                : `/${stateSEO}`
        }/${locationSEO}`
    }

    if (location && location.type === "departement") {
        let stateLibelle = reverseCodeRegions[location.codeRegion]
        let stateSEO = regionsURL[stateLibelle]

        let department = departments.filter(
            d => d.code === location.codeDepartement
        )
        if (department.length === 0) {
            return false
        }
        let departmentSEO = department[0].seo

        return `/${stateSEO}/${departmentSEO}`
    }

    if (location && location.type === "region") {
        let stateLibelle = reverseCodeRegions[location.codeRegion]
        let stateSEO = regionsURL[stateLibelle]

        return `/${stateSEO}`
    }

    return false
}

export {
    translateAlgoliaHitsToLocations,
    locationContains,
    locationIntersects
}